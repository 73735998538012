:root {
	font-family: 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	color-scheme: light dark;
	color: #444;
	background: #fafafa;
}

@media (prefers-color-scheme: dark) {
	:root {
		color: #000;
		background: #f2f7fb;
	}
}

body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
}

#app > main {
	display: flex;
	margin: 0 auto;
	min-height: calc(100vh - 3.5rem);
	max-width: 720px;
	/*align-items: center;*/
	justify-content: center;
}

.service {
	position: fixed;
	right: 2vh;
	bottom: 7vh;
	width: 7vh;
	height: 7vh;
	background: #4d96e3 url("/assets/service.png") no-repeat center;
	background-size: 50%;
	border-radius: 50%;
}
